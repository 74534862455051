<template>
  <div class="gc-shade"><slot /></div>
</template>

<style scoped>
  /* You need to use `text-color` class on this layout to set shade color */
  .gc-shade {
    @apply relative py-1;

    &::before,
    &::after {
      content: '';
      @apply pointer-events-none absolute left-0 right-4 z-10 h-4 from-current to-transparent;
    }
  }

  .gc-shade::before {
    @apply top-0 bg-gradient-to-b;
  }

  .gc-shade::after {
    @apply bottom-0 bg-gradient-to-t;
  }
</style>
